<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <el-icon>
            <menu />
          </el-icon>
          Points Issued Records
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-date-picker
          type="date"
          placeholder="Start Issued Time"
          v-model="query.startCreatedTime"
          class="handle-input"
          style="margin-right: 10px"
        ></el-date-picker>
        <el-date-picker
          type="date"
          placeholder="End Issued Time"
          v-model="query.endCreatedTime"
          class="handle-input"
          style="margin-right: 10px"
        ></el-date-picker>
        <el-button type="primary" @click="handleSearch">
          <el-icon>
            <search />
          </el-icon>
          Refine
        </el-button>
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @sort-change="changeSort"
        :default-sort="{ prop: 'CreatedTime', order: 'DESC' }"
      >
        <el-table-column
          prop="title"
          label="Title"
          :width="this.$widthRatio * 320 + 'px'"
        ></el-table-column>
        <el-table-column
          prop="points"
          label="Points"
          :width="this.$widthRatio * 160 + 'px'"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="Points"
        >
          <template #default="scope">
            {{ scope.row.points
            }}{{ scope.row.issuedType === "Normal" ? "" : ` (${scope.row.issuedType})` }}
          </template>
        </el-table-column>
        <el-table-column prop="comment" label="Comment"></el-table-column>
        <el-table-column
          prop="createdTime"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="CreateTime"
          label="Issued Time"
          :width="this.$widthRatio * 160 + 'px'"
          :formatter="$tableCellFormatter.formatDate"
        ></el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="itemTotal"
          :page-count="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getData } from "../../service/api";

export default {
  name: "pointsRecordList",
  data() {
    return {
      controllerUrl: "/points",
      query: {
        studentId: null,
        startCreatedTime: null,
        endCreatedTime: null,
        orderBy: "CreatedTime",
        orderDirection: "DESC",
        pageIndex: 1,
        pageSize: 20,
      },
      tableData: [],
      itemTotal: 0,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
    };
  },
  created() {
    let studentId = this.$user.getUsername();
    if (studentId) {
      this.query.studentId = studentId;
      this.initAndLoadData();
    }
  },
  methods: {
    initAndLoadData() {
      this.loadData();
    },
    loadData() {
      getData(this.controllerUrl, this.query).then((res) => {
        let resData = res.result;
        this.tableData = resData.list;
        this.query.pageIndex = resData.pageIndex;
        this.query.pageSize = resData.pageSize;
        this.itemTotal = resData.count;
        this.pageTotal = Math.ceil(resData.count / resData.pageSize);
      });
    },
    handleSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    changeSort(sortData) {
      if (sortData.order) {
        this.query.orderBy = sortData.prop;
        this.query.orderDirection = sortData.order === "ascending" ? "asc" : "desc";
      } else {
        this.query.orderBy = "";
        this.query.orderDirection = "";
      }
      this.loadData();
    },
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 1.17rem;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
